<template>
  <div class="marketplaceForm">
    <form v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="title">Title:</label>
        <input
          type="text"
          v-model="marketplace.title"
          :class="{ 'form-control': true, 'is-invalid': errors.has('title') }"
          name="title"
          id="title"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid title.
        </div>
      </div>

      <div class="form-group">
        <label for="slug">Slug:</label>
        <input
          type="text"
          v-model="marketplace.slug"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('slug'),
          }"
          name="slug"
          id="slug"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid slug.
        </div>
      </div>

      <div class="form-group">
        <label for="meta_title">Meta Title (SEO):</label>
        <input
          type="text"
          v-model="marketplace.meta_title"
          class="form-control"
          name="meta_title"
          id="meta_title"
          placeholder=""
        />
        <div class="invalid-feedback">
          Please provide a valid meta title.
        </div>
      </div>

      <div class="form-group">
        <label for="meta_description">Meta Description (SEO):</label>
        <textarea
          v-model="marketplace.meta_description"
          class="form-control"
          name="meta_description"
          id="meta_description"
          placeholder=""
        ></textarea>
        <div class="invalid-feedback">
          Please provide a valid meta description.
        </div>
      </div>



      <div class="form-group">
        <label for="affiliate">Affiliate:</label>
        <input
          type="text"
          v-model="marketplace.affiliate"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('affiliate')
          }"
          name="affiliate"
          id="affiliate"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid affiliate.
        </div>
      </div>

      <div class="form-group">
        <label for="thumbnail">Thumbnail:</label>
        <input
          type="text"
          v-model="marketplace.thumbnail"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('thumbnail')
          }"
          name="thumbnail"
          id="thumbnail"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid thumbnail.
        </div>
      </div>

      <fieldset class="form-group">
        <div class="row">
          <legend class="col-form-label col-sm-2 pt-0">Images Content</legend>
          <div class="col-sm-10">
            <div class="row">
              <label for="image">Image List:</label>
              <ul>
                <li
                  v-for="(image, index) in marketplace.images.list"
                  v-bind:key="index"
                >
                  <strong>Alt:</strong> {{ image.alt }}, <strong>Href:</strong>
                  {{ image.href }},
                  <a href="#" @click="removeLink($event, index)">Remove</a>
                </li>
              </ul>
            </div>
            <div class="row">
              <label for="image_alt">Image Label:</label>
              <input
                type="text"
                v-model="product.image.alt"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('image_alt')
                }"
                name="image_alt"
                id="image_alt"
                placeholder=""
              />
            </div>

            <div class="row">
              <label for="image_href">Image Link:</label>
              <input
                type="text"
                v-model="product.image.href"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('image_href')
                }"
                name="image_href"
                id="image_href"
                placeholder=""
              />
            </div>
            <div class="row">
              <button
                type="button"
                class="btn btn-primary"
                @click="addImage"
                style="margin-top: 20px"
              >
                Add Image to List
              </button>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="form-group">
        <label for="short_desc">Short Description:</label>
        <textarea
          v-model="marketplace.short_desc"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('short_desc')
          }"
          name="short_desc"
          id="short_desc"
          placeholder=""
          v-validate="'required'"
        ></textarea>
        <div class="invalid-feedback">
          Please provide a valid short description.
        </div>
      </div>

      <div class="form-group">
        <label for="short_desc">Long Description:</label>
        <editor
          api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86"
          name="body"
          id="body"
          v-model="marketplace.full_desc"
          class="wysiwyg-editor"
          :init="{
            plugins:
              'wordcount link image fullscreen lists textcolor colorpicker'
          }"
        ></editor>
        <div class="invalid-feedback">
          Please provide a valid long description.
        </div>
      </div>

      <div class="form-group">
        <label for="affiliate">Link:</label>
        <input
          type="text"
          v-model="marketplace.link"
          :class="{ 'form-control': true, 'is-invalid': errors.has('link') }"
          name="link"
          id="link"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid link (http, https).
        </div>
      </div>

      <div class="form-group">
        <label for="recommendation">Recommendation:</label>
        <textarea
          rows="10"
          cols="50"
          v-model="marketplace.recommendation"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('recommendation')
          }"
          name="recommendation"
          id="recommendation"
          placeholder=""
          v-validate="'required'"
        ></textarea>
        <div class="invalid-feedback">
          Please provide a valid recommendation.
        </div>
      </div>

      <div class="form-group">
        <label for="sort_date">Sort Date:</label>
        <datepicker
          placeholder="Sort Date"
          v-model="marketplace.sort_date"
          name="uniquename"
          input-class="form-control"
        ></datepicker>
      </div>

      <select class="form-control" name="enabled" v-model="marketplace.enabled">
        <option value="0">No</option>
        <option value="1">Yes</option>
      </select>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import Datepicker from "vuejs-datepicker";

export default {
  methods: {
    addImage: function() {
      if (
        this.product.image.href.length > 0 &&
        this.product.image.alt.length > 0
      ) {
        let productImage = {
          href: this.product.image.href,
          alt: this.product.image.alt
        };

        this.marketplace.images.list.push(productImage);
        this.product.image.href = "";
        this.product.image.alt = "";
      } else {
        alert("Must add a product link");
      }
    },

    removeLink: function(e, ind) {
      e.preventDefault();
      this.marketplace.images.list.splice(ind, 1);
    },

    saveChanges: function() {
      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };

        if (!isNaN(this.$route.params.id)) {
          this.$http
            .patch(
              process.env.VUE_APP_ROOT_API +
                "/marketplace/" +
                this.$route.params.id,
              this.marketplace,
              options
            )
            .then(response => {
              if (response.status === 200) {
                alert("Record updated successfully");
              }
            });
        } else if (this.$route.params.id == "add") {
          this.$http
            .post(
              process.env.VUE_APP_ROOT_API + "/marketplace",
              this.marketplace,
              options
            )
            .then(response => {
              if (response.status === 200) {
                alert("Record created successfully");
              }
            });
        }
      }
    }
  },

  data() {
    return {
      marketplace: {
        images: {
          list: []
        }
      },

      product: {
        image: {
          href: "",
          alt: ""
        }
      }
    };
  },

  components: {
    editor: Editor,
    datepicker: Datepicker
  },

  created() {
    if (!isNaN(this.$route.params.id)) {
      this.$http
        .get(
          process.env.VUE_APP_ROOT_API + "/marketplace/" + this.$route.params.id
        )
        .then(response => {
          this.marketplace = response.data;
          this.marketplace.affiliate = response.data["affiliate_name"];
          delete this.marketplace.affiliate_name;
          console.log(this.marketplace);
        });
    } else if (this.$route.params.id !== "add") {
      alert("Invalid parameter.");
    }
  }
};
</script>
<style></style>
