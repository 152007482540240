var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"marketplaceForm"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveChanges.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Title:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.marketplace.title),expression:"marketplace.title"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{ 'form-control': true, 'is-invalid': _vm.errors.has('title') },attrs:{"type":"text","name":"title","id":"title","placeholder":""},domProps:{"value":(_vm.marketplace.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.marketplace, "title", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid title. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"slug"}},[_vm._v("Slug:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.marketplace.slug),expression:"marketplace.slug"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
          'form-control': true,
          'is-invalid': _vm.errors.has('slug'),
        },attrs:{"type":"text","name":"slug","id":"slug","placeholder":""},domProps:{"value":(_vm.marketplace.slug)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.marketplace, "slug", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid slug. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"meta_title"}},[_vm._v("Meta Title (SEO):")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.marketplace.meta_title),expression:"marketplace.meta_title"}],staticClass:"form-control",attrs:{"type":"text","name":"meta_title","id":"meta_title","placeholder":""},domProps:{"value":(_vm.marketplace.meta_title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.marketplace, "meta_title", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid meta title. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"meta_description"}},[_vm._v("Meta Description (SEO):")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.marketplace.meta_description),expression:"marketplace.meta_description"}],staticClass:"form-control",attrs:{"name":"meta_description","id":"meta_description","placeholder":""},domProps:{"value":(_vm.marketplace.meta_description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.marketplace, "meta_description", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid meta description. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"affiliate"}},[_vm._v("Affiliate:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.marketplace.affiliate),expression:"marketplace.affiliate"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
          'form-control': true,
          'is-invalid': _vm.errors.has('affiliate')
        },attrs:{"type":"text","name":"affiliate","id":"affiliate","placeholder":""},domProps:{"value":(_vm.marketplace.affiliate)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.marketplace, "affiliate", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid affiliate. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"thumbnail"}},[_vm._v("Thumbnail:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.marketplace.thumbnail),expression:"marketplace.thumbnail"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
          'form-control': true,
          'is-invalid': _vm.errors.has('thumbnail')
        },attrs:{"type":"text","name":"thumbnail","id":"thumbnail","placeholder":""},domProps:{"value":(_vm.marketplace.thumbnail)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.marketplace, "thumbnail", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid thumbnail. ")])]),_c('fieldset',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('legend',{staticClass:"col-form-label col-sm-2 pt-0"},[_vm._v("Images Content")]),_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"row"},[_c('label',{attrs:{"for":"image"}},[_vm._v("Image List:")]),_c('ul',_vm._l((_vm.marketplace.images.list),function(image,index){return _c('li',{key:index},[_c('strong',[_vm._v("Alt:")]),_vm._v(" "+_vm._s(image.alt)+", "),_c('strong',[_vm._v("Href:")]),_vm._v(" "+_vm._s(image.href)+", "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.removeLink($event, index)}}},[_vm._v("Remove")])])}),0)]),_c('div',{staticClass:"row"},[_c('label',{attrs:{"for":"image_alt"}},[_vm._v("Image Label:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.product.image.alt),expression:"product.image.alt"}],class:{
                'form-control': true,
                'is-invalid': _vm.errors.has('image_alt')
              },attrs:{"type":"text","name":"image_alt","id":"image_alt","placeholder":""},domProps:{"value":(_vm.product.image.alt)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.product.image, "alt", $event.target.value)}}})]),_c('div',{staticClass:"row"},[_c('label',{attrs:{"for":"image_href"}},[_vm._v("Image Link:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.product.image.href),expression:"product.image.href"}],class:{
                'form-control': true,
                'is-invalid': _vm.errors.has('image_href')
              },attrs:{"type":"text","name":"image_href","id":"image_href","placeholder":""},domProps:{"value":(_vm.product.image.href)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.product.image, "href", $event.target.value)}}})]),_c('div',{staticClass:"row"},[_c('button',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"20px"},attrs:{"type":"button"},on:{"click":_vm.addImage}},[_vm._v(" Add Image to List ")])])])])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"short_desc"}},[_vm._v("Short Description:")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.marketplace.short_desc),expression:"marketplace.short_desc"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
          'form-control': true,
          'is-invalid': _vm.errors.has('short_desc')
        },attrs:{"name":"short_desc","id":"short_desc","placeholder":""},domProps:{"value":(_vm.marketplace.short_desc)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.marketplace, "short_desc", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid short description. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"short_desc"}},[_vm._v("Long Description:")]),_c('editor',{staticClass:"wysiwyg-editor",attrs:{"api-key":"h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86","name":"body","id":"body","init":{
          plugins:
            'wordcount link image fullscreen lists textcolor colorpicker'
        }},model:{value:(_vm.marketplace.full_desc),callback:function ($$v) {_vm.$set(_vm.marketplace, "full_desc", $$v)},expression:"marketplace.full_desc"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid long description. ")])],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"affiliate"}},[_vm._v("Link:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.marketplace.link),expression:"marketplace.link"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{ 'form-control': true, 'is-invalid': _vm.errors.has('link') },attrs:{"type":"text","name":"link","id":"link","placeholder":""},domProps:{"value":(_vm.marketplace.link)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.marketplace, "link", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid link (http, https). ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"recommendation"}},[_vm._v("Recommendation:")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.marketplace.recommendation),expression:"marketplace.recommendation"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
          'form-control': true,
          'is-invalid': _vm.errors.has('recommendation')
        },attrs:{"rows":"10","cols":"50","name":"recommendation","id":"recommendation","placeholder":""},domProps:{"value":(_vm.marketplace.recommendation)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.marketplace, "recommendation", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid recommendation. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"sort_date"}},[_vm._v("Sort Date:")]),_c('datepicker',{attrs:{"placeholder":"Sort Date","name":"uniquename","input-class":"form-control"},model:{value:(_vm.marketplace.sort_date),callback:function ($$v) {_vm.$set(_vm.marketplace, "sort_date", $$v)},expression:"marketplace.sort_date"}})],1),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.marketplace.enabled),expression:"marketplace.enabled"}],staticClass:"form-control",attrs:{"name":"enabled"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.marketplace, "enabled", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("No")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Yes")])]),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group signup-carousel-buttons"},[_c('button',{staticClass:"btn btn-primary"},[_vm._v("Save")])])
}]

export { render, staticRenderFns }